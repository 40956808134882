@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Poppins", sans-serif;
}

.bg-gradient {
  background: var(
    --8585852-paints,
    linear-gradient(85deg, #5352b5 -20.5%, #9f7ddb 96.23%),
    #858585
  );
}

.bg-gradient-2 {
  background: var(
    --GradientMiddle,
    linear-gradient(57deg, #5352b5 18.11%, #9f7ddb 92.1%)
  );
}
