@import url('https://fonts.googleapis.com/css2?family=Saira:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: Saira !important;

}

.legal_wrapper {
  max-width: 1240px;
  margin: 0 auto;
}
